.search-box {
  &_bar {
    height: 32px;
    border-radius: 3px;
    background-color: #EBEBEB;
    color: #495057;
    width:100%;
    position: relative;

    & input {
      padding: 0 35px;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 0;
      font-size: 14px;
      @extend .ellipsis;
  
      &::placeholder {
        color: #9B9B9B;
      }
  
      &:focus {
        outline: none;
      }
    }
  }

  &_icon-search {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &_icon-close {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 6px 0 3px 0;
  } 

  &_search-item {
    margin-left: 7px;
    color: #296CCC;
    text-decoration: underline;
    cursor: pointer;
  }
  
  &_sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: relative;
  }

  &_sort-icon {
    padding-left: 3px;
    cursor: pointer;
  }
}