.text-list {
  &_item, &_item--arrow, &_item--arrow-link {
    list-style: none;
    position: relative;
    margin-left: 18px;
  }

  &_item::before {
    content: "";
    position: absolute;
    left: -15px;
    top: 8px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
  }

  &_item--arrow::before {
    content: "\2192";
    position: absolute;
    left: -18px;
  }

  &_item--arrow-link::before {
    content: "\2192";
    position: absolute;
    left: -18px;
    color: #0066FF;
  }
}