.list-item {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &:last-child {
    margin-bottom: 20px;
  }

  &_title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    font-family: 'Quicksand', sans-serif;
    color: #006CD3;
    cursor: pointer;
  }

  &_subtitle-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #6B6B6B;

    margin: 6px 0;

    white-space: nowrap;

    & > * {
      margin-bottom: 2px;
    }

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  &_author {
    color: #4E5371;
    text-decoration: underline;
    cursor: pointer;
  }

  &_content {
    color: #2E2E2E;
  }

  &_button-box {
    display: flex;
    align-items: center;
    margin-top: 4px;
    min-height: 25px;

    & > *:nth-child(2) {
      margin-left: 15px;
    }
  }

  &_metrics-box {
    display: flex;
    align-items: center;
    margin-left: 5px;
    border-left: 1px solid #CCCCCC;
  }

  &_metrics-button {
    display: flex;
    align-items: center;
    padding: 1.5px 5px;
    margin: 0 0 0 5px;
    border: 1px solid transparent;

    font-family: "Roboto regular", Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    cursor: pointer;

    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: #333333;
    }

    &:hover {
      border: 1px solid #E9E9E9; 
      background-color: #F8F8F8;
    }

    & > *:not(:first-child) {
      margin-left: 4px;
    }
  }
}