.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: 'Quicksand', sans-serif;
  color: #4E4E4E;

  height: 100%;

  border-bottom: 1px solid #D8D8D8;

  &_title {
    font-size: 24px;
    padding-left: 20px;
    cursor: pointer;
    text-decoration: none;

    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }

    @media (max-width: $screen-sm) {
      font-size: 14px;
    }
  }

  &_info-box {
    display: flex;
    align-items: center;

    position: relative;

    font-size: 16px;
    font-weight: 500;

    height: 100%;

    @media (max-width: $screen-sm) {
      font-size: 12px;
    }
  }

  &_link {
    padding: 0 15px;
    outline: 0px;
    transition: color .1s ease-out;
    text-decoration: none;

    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }

    &:not(:first-of-type) {
      border-left: 1px solid grey;
    }

    &:hover {
      color: grey;
    }
  }

  &_icon{
    position: absolute;
    left: -7px;
    top: 16px;
  }
}