.card {
  margin-bottom: 2rem;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.3);

  &_header {
    height: 40px;
    padding: 10px 15px;
    border-bottom: 1px solid #D8D8D8;
    font-weight: 700;
  }

  &_body {
    padding: 15px 15px 20px 35px;
  }
}