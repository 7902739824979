.sidebar {
  flex: 0 0 250px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  padding: 30px 0 0 20px;

  color: #373737;

  &_item-box {
    display: flex;
    flex-direction: column;
  }

  &_item {
    flex: 0 0 40px;
    max-height: 40px;

    display: flex;
    align-items: center;

    cursor: pointer;

    &:focus {
      outline: none;
    }

    &--active {
      color: #0066FF;
      font-weight: 700;

      & > .sidebar_icon {
        color: #0066FF;
      }
    }

    &--inactive {
      opacity: .6;
      pointer-events: none;
      cursor: no-drop;
    }
  }

  &_icon {
    margin-right: 8px;
    color: #8C8C8C
  }

  &_title {

  }

  &_widget {
    flex: 0 0 57px;

    display: flex;
    align-items: center;

    padding: 0 .8rem;
    margin: 0 27px 27px 0;
    color: #296CCC;
    background-color: #E6F2FF;
    font-weight: 700;
    font-size: 14px;
    border-radius: 10px;

    & > a {
      &:link,
      &:visited,
      &:hover,
      &:focus,
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &_widget-icon {
    flex: 0 0 35px;

    font-size: 26px;
  }
}