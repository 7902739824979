.token {
  &_container {
    display: inline-table;
    padding: 3px 5px;
    height: auto;
    min-height: 66px;

    &.disabled {
      opacity: .5;
      background-color: #eee;
      color: white;

      & .token_icon {
        cursor: not-allowed;
      }
    }
  }

  &_input {
    border: 0;
    font-size: inherit;
    color: inherit;
    padding: 5px 2px;
    background-color: transparent;
    width: 100%;
    outline: none;
  }

  &_item {
    display: inline-block;
    font-size: inherit;
    font-weight: inherit;
    padding: .5em .75em;
    margin: .25em .1em;
    color: #4A4A4A;
    border-radius: 4px;
    background-color: #F3F3F3;
    user-select: none;
    cursor: default;
    transition: background-color 200ms linear;

    &.error {
      color: white;
      background-color: #D0021B;
    }

    &:hover:not(.error) {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &_icon {
    display: inline;
    margin-left: 15px;
    cursor: pointer;
    position: relative;

    &::before, &::after {
      position: absolute;
      right: 5px;
      bottom: 2px;
      content: ' ';
      height: 10px;
      width: 1px;
      background-color: #333;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}