.overlay {
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 50px;
  padding: 40px;
  z-index: 9999;

  background-color: #ffffff;
}