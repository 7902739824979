
.form-group {
  margin-bottom: 14px;

  &--has-error {
    margin-bottom: 0;
  }

  &--inline {
    display: flex;
    align-items: center;

    & > *:first-child {
      flex: 0 0 auto;
      margin-right: 5px;
      margin-bottom: 16px;
    }

    & > *:not(:first-child) {
      flex: 1 1 auto;
    }
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
  }

  &::placeholder {
    color: #9B9B9B;
  }

  &--disabled,
  &[disabled] {
    opacity: .5;
  }

  &--error {
    border: 2px solid #a94442;
  }
}

.form-label {
  display: inline-block;
  margin-bottom: .2rem;
  font-size: 12px;
}

textarea.form-control {
  height: auto;
}