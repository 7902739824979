.loader {
  text-align: center;

  &_overlay {
    position: relative;
    height: 100%;
  }

  &_more-container {
    width: 100%;
    display: block;
    padding: 20px 0;
  }

  &_circle {
    width: 18px;
    height: 18px;
    opacity: .9;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out both;

    &:nth-of-type(3) {
      animation-delay: -0.30s;
    }

    &:nth-of-type(2) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(.9);
  }
}