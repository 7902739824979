.to-top-button {
  position: fixed;
  right: 20px;
  top: 45%;
  bottom: 55%;
  transform: translateY(-50%);
  z-index: 9999;
  cursor: pointer;
  color: rgba(0,0,0,.4);
  font-size: 14px;

  @media (max-width: $screen-xl) {
    right: 12px;
  }
}