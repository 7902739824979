.footer {
  flex: 0 0 80px;

  display: flex;
  flex-direction: column;

  margin-right: 40px;
  padding-left: 20px;
  border-top: 1px solid #D8D8D8;

  color: #2E2E2E;

  &_logo-box {
    flex: 0 0 50px;
    max-height: 50px;

    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 35px;
    }
  }

  &_text-box {
    flex: 0 0 30px;

    display: flex;
    max-height: 30px;

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &_terms-updated {
    font-size: 10px;
    margin-left: 5px;
  }
}