.hidden {
  display: none;
}

.show {
  display: block;
}

.flex {
  display: flex;
  align-items: center;
}