.badge {
  font-size: 10px;
  background-color: #e9e9e9;
  padding: 5px;
  display: inline-block;
  font-weight: 400;
  line-height: .65;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  color: #6B6B6B;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}