.dropdown {
  position: absolute;
  top: 100%;
  right: 0;

  display: flex;
  flex-direction: column;

  padding: 0.5rem 0;
  margin: 0.125rem 0 0;

  background-color: white;
  max-width: 500px;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.4) 0 6px 20px;

  &_item {
    padding: 0.25rem 1.5rem;
    cursor: pointer;

    @extend .ellipsis;

    &:hover {
      color: white;
      background-color: #017BFF;
    }
  }
}