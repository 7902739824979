.text--h1 {
  color: #363737;
  font-size: 24px;
  font-weight: 400;
  font-family: 'Quicksand', sans-serif;
}

.text--title {
  font-size: 20px;
  font-family: 'Quicksand', sans-serif;
  color: #0066FF;
}

.text--subtitle {
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: grey;
}

.text--title-bold {
  font-size: 14px;
  font-weight: 700;
}

.text--title-aside {
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #363737;
  font-weight: 700;
  margin-bottom: 10px;
}

.text--center {
  text-align: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lowercase {
  text-transform: lowercase;
}