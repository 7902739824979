.autocomplete {
  position: relative;

  &_list {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;

    top: 100%;
    left: 0;
    right: 0;

    & > * {
      padding: 2px 0 2px 10px;
      font-size: 12px;
      cursor: pointer;
      background-color: #fff;
    }

    & > *:hover {
      background-color: #e9e9e9;
    }
  }
}
