.button {
  border: 0;
  border-radius: 9px;

  cursor: pointer;

  font-size: 16px;

  align-items: center;
  justify-content: center;
  display: inline-flex;
  line-height: 1.5;
  padding: 7px 40px;

  background-color: #BDBDBD;
  color: white;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled, &[disabled] {
    cursor: no-drop;
    opacity: .7;
  }

  &--sm {
    padding: 0.375rem 0.75rem;
  }

  &--blue {
    background-color: #0066FF;
  }

  &--white {
    background-color: #ffffff;
    color: inherit;
    border: 1px solid #4C4C4C;
  }

  & > img {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
}
