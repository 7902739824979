.modal {
  &_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    background-color: rgba(0,0,0,.6);
    z-index: 999;
    color: #515151;
  }

  & {
    flex: 0 0 auto;
    margin: 40px auto;
  }

  &_frame {
    width: 450px;
    height: 550px;
    border: none;
    border-radius: 4px;
  }

  &_backdrop--centered {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 999;
    color: #515151;
  }

  &--centered {
    @include center-horizontal-vertical;

    width: 700px;
    background-color: white;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,.3);

    display: flex;
    flex-direction: column;
    padding: 22px 30px;
  }
}