.checkbox {
  &_input {
    display: none;
  }

  &_label {
    display: block;
    cursor: pointer;

    margin-bottom: 0;
    padding-left: 30px;

    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
    }
  }

  &_label--error {
    &::before {
      border-color: #a94442;
    }
  }

  // checkbox background square
  // ----------------------------------
  &_input + &_label::before {
    background-color: white;

    border-width: 1px;
    border-style: solid;
    border-radius: 2px;

    display: block;
    height: 13px;
    width:  13px;
    top: 5px;
    left: 0;

    transition: border-color .15s 0s ease-in-out;
  }

  &_input + &_label:hover::before {
    border-color: black;
  }

  &_input:checked + &_label::before {
    background-color: #d3d3d3;
    border-width: 1px;

    transition: background-color .05s 0s ease-in-out;
  }

  &_input:checked + &_label:hover::before {
    background-color: rgb(143, 156, 143);
  }

  // checkbox checkmark
  // ----------------------------------
  &_input:checked + &_label::after {
    border: solid black;

    border-width: 0 2px 2px 0;
    height: 10px;
    width: 6px;
    top: 5px;
    left: 4px;
    transform: rotate(45deg);
  }
}